<script setup lang="ts"></script>

<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="mt-5">Pagina niet gevonden</h1>
                <p>Deze pagina bestaat niet</p>
                <img
                    src="./assets/images/hond-zoekt.png"
                    alt=""
                    width="342"
                    height="120"
                />
            </div>
        </div>
    </div>
</template>
