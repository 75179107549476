import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import Layout from '@/Layout.vue';
import NotFound from '@/NotFound.vue';
import RouteLoading from '@/RouteLoading.vue';
import config from '@/temp/config';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-vue';
import { inject, ref, watch } from 'vue';
import { layoutServiceFactory } from './lib/layout-service-factory';
import { isClient } from './utils/is-server';
// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static routing isn't enough -
// we need to load dynamic route data from Sitecore when the client side route changes.
// So vue-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.
export default /*@__PURE__*/ _defineComponent({
    __name: 'RouteHandler',
    props: {
        route: {},
        context: {}
    },
    setup(__props) {
        const props = __props;
        const notFound = ref(true);
        const defaultLanguage = ref(config.defaultLanguage);
        const loading = ref(true);
        const { store: jssStore } = inject('jss');
        const { state: jssState } = jssStore;
        // if the app state has routeData, we don't need to load it and don't need a loading screen
        if (jssState.routeData) {
            loading.value = false;
        }
        // route path from vue router - if route was resolved, it's not a 404
        if (props.route !== null) {
            notFound.value = false;
        }
        // if we have an initial SSR state, and that state doesn't have a valid route data,
        // then this is a 404 route.
        if (!jssState.routeData) {
            notFound.value = true;
        }
        // if we have initial context data, and that context data has a language defined, set the default language
        // (this makes the language of content follow the Sitecore context language cookie)
        // note that a route-based language (i.e. /de-DE) will override this default; this is for home.
        if (jssState.sitecoreContext && jssState.sitecoreContext.language) {
            defaultLanguage.value = jssState.sitecoreContext.language;
        }
        watch(() => props.route, (newRoute, oldRoute) => {
            if (newRoute.hash !== '' && newRoute.path === oldRoute.path) {
                return;
            }
            // if in Sitecore editor - force reload instead of route data update
            // avoids confusing Sitecore's editing JS
            if (isEditorActive()) {
                window.location.assign(newRoute.path);
                return;
            }
            updateRouteData();
        });
        const storeContext = inject('storeContext');
        const updateRouteData = () => {
            if (isClient()) {
                // add current route to body attribute so plugins outside this vue-app can react on changes (e.q. chat-widget)
                document.body.dataset.route = props.route.fullPath.replace('/', '');
            }
            let sitecoreRoutePath = Array.isArray(props.route.params.sitecoreRoute)
                ? props.route.params.sitecoreRoute.join('/')
                : props.context.appStart; // app start route from context
            if (!sitecoreRoutePath.startsWith('/')) {
                sitecoreRoutePath = `/${sitecoreRoutePath}`;
            }
            // Geef nooit een language mee aan de layout service, zodat de service automatisch
            // de actuele language geeft van de site waarin de app embedded is.
            const language = undefined;
            loading.value = true;
            // sitecore base path to app (eg. /apps/salesfunnel-ziezo-premie-berekenen/)
            // concat with path to route (eg. /start)
            sitecoreRoutePath = `${props.context.appBase}${sitecoreRoutePath}`;
            // instantiate layout service
            const layoutServiceInstance = layoutServiceFactory.create(props.context);
            // get the route data for the new route
            layoutServiceInstance.fetchLayoutData(sitecoreRoutePath, language).then(routeData => {
                // Update the JSS store instance with the fetched data.
                // This will signal the RouteHandler to update/re-render, as well as any components
                // that are referencing the JSS store instance in their `data` object.
                jssStore.setSitecoreData(routeData);
                // Update login info in store
                storeContext.ophalenLoginInfo(routeData?.sitecore?.context);
                // Update language in store
                storeContext.setLanguage(jssState.sitecoreContext.language);
                notFound.value = !routeData?.sitecore?.route;
                loading.value = false;
            });
        };
        // if no existing routeData is present (from SSR), get Layout Service fetching the route data
        if (!jssState.routeData) {
            updateRouteData();
        }
        if (isClient()) {
            // add current app type to body attribute so plugins outside this vue-app can react on changes (e.q. chat-widget)
            document.body.dataset.appType = props.context?.appType;
        }
        return (_ctx, _cache) => {
            return (notFound.value && !loading.value)
                ? (_openBlock(), _createBlock(NotFound, {
                    key: 0,
                    context: _unref(jssState).sitecoreContext
                }, null, 8, ["context"]))
                : (loading.value)
                    ? (_openBlock(), _createBlock(RouteLoading, { key: 1 }))
                    : (_openBlock(), _createBlock(Layout, {
                        key: 2,
                        route: _unref(jssState).routeData
                    }, null, 8, ["route"]));
        };
    }
});
