import { computed } from 'vue';
import { useStore } from 'vuex';

const mapStateComp = module => {
    const store = useStore();
    return Object.fromEntries(
        Object.keys(module !== undefined ? store.state[module] : store.state).map(key =>
            module !== undefined
                ? [key, computed(() => store.state[module][key])]
                : [key, computed(() => store.state[key])]
        )
    );
};

const mapGettersComp = module => {
    const store = useStore();

    return Object.fromEntries(
        Object.keys(store.getters).map(getter =>
            module !== undefined && getter.startsWith(module)
                ? [getter.substring(module.length + 1), computed(() => store.getters[getter])]
                : [getter, computed(() => store.getters[getter])]
        )
    );
};

const mapMutationsComp = module => {
    const store = useStore();

    return Object.fromEntries(
        Object.keys(store._mutations).map(mutation =>
            module !== undefined && mutation.startsWith(module)
                ? [mutation.substring(module.length + 1), value => store.commit(mutation, value)]
                : [mutation, value => store.commit(mutation, value)]
        )
    );
};

const mapActionsComp = module => {
    const store = useStore();
    return Object.fromEntries(
        Object.keys(store._actions).map(action =>
            module !== undefined && action.startsWith(module)
                ? [action.substring(module.length + 1), value => store.dispatch(action, value)]
                : [action, value => store.dispatch(action, value)]
        )
    );
};

export { mapStateComp, mapGettersComp, mapMutationsComp, mapActionsComp };
