import { ApiClient } from '@/utils/apiClient';

const createStore = app => {
    const state = {
        ziekenhuizen: {},
        pending: false,
        error: false
    };

    const getters = {
        getHealthcareProviders: state => state.ziekenhuizen,
        getZiekenhuizen: state => key => state.ziekenhuizen[key] || null,
        getPending: state => state.pending,
        getError: state => state.error
    };

    const actions = {
        /**
         * Zoeken van ziekenhuizen in store of anders van server.
         *
         * @param {string}   pakket        De verkorte code van het pakket (zvw, zvwl).
         * @param {string}   locatie       De locatie voor het zoeken.
         *
         * @return {type} Return lijst van ziekenhuizen met contractinformatie
         */
        async zoekZiekenhuizen({ getters, dispatch, commit }, { pakket, locatie }) {
            const key = `${pakket}|${locatie}`;
            commit('SET_ERROR', false);

            // check bestaande zoekenhuizen
            let result = getters.getZiekenhuizen(key);
            if (result) return result;

            // opnieuw zoeken
            result = await dispatch('ophalenZiekenhuizen', { pakket, locatie });
            return result;
        },

        /**
         * Ophalen van ziekenhuizen van de server.
         *
         * Gebruikt de call ophalenziekenhuizen van de salesfunnel client controller
         * voor het ophalen van contractinformatie voor ziekenhuizen voor het opgegeven
         * pakker en in de buurt van de opgegeven locatie.
         *
         * @param {string}   pakket        De verkorte code van het pakket (zvw, zvwl).
         * @param {string}   locatie       De locatie voor het zoeken.
         *
         * @return {type} Return lijst met ziekenhuizen met contractinformatie
         */
        async ophalenZiekenhuizen({ commit }, { pakket, locatie }) {
            commit('SET_ERROR', false);
            commit('SET_PENDING', true);

            let ziekenhuizen;
            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const propositie = appContext?.propositie;

                // ophalen ziekenhuizen
                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/salesfunnelclient/ophalenziekenhuizen`,
                    {
                        Locatie: locatie,
                        Pakket: pakket,
                        Propositie: propositie ?? 'BAP'
                    }
                );

                if (data?.isSucces === false) {
                    commit('SET_ERROR', true);
                }

                // opslaan ziekenhuizen in store
                if (data?.zorginstellingen?.length > 0) {
                    ziekenhuizen = {
                        lat: data.lat,
                        lng: data.lng,
                        zorginstellingen: data.zorginstellingen
                    };

                    commit('SET_ZIEKENHUIZEN', { pakket, locatie, ziekenhuizen });
                }
            } catch (error) {
                commit('SET_ERROR', true);
            } finally {
                commit('SET_PENDING', false);
            }
            return ziekenhuizen || null;
        }
    };

    const mutations = {
        SET_ZIEKENHUIZEN: (state, { pakket, locatie, ziekenhuizen }) => {
            const key = `${pakket}|${locatie}`;
            state.ziekenhuizen = { ...state.ziekenhuizen, [key]: ziekenhuizen };
        },
        SET_PENDING(state, value) {
            state.pending = value;
        },
        SET_ERROR(state, value) {
            state.error = value;
        }
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
};

export const contractsStoragePaths = ['contracten'];

export default app => createStore(app);
