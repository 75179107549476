/**
 * Custom validator for checkboxes
 * @param {String} value
 * @returns {Boolean}
 */
export const isChecked = value => value === '1';

/**
 * Custom optional validator for checkboxes
 * @param {String} value
 * @returns {Boolean}
 */
export const isCheckedIf = params => value => {
    return params ? value === '1' : true;
};

/**
 * Custom validator to check if value only contains letters and dots
 * Used for initials field
 * @param {String} value
 * @returns
 */
export const onlyCharsAndDots = value => /^[a-zA-Z_.-]+$/.test(value);

/**
 * Custom postcode validatie op basis van meegegeven regex
 * Vanuit prospect lijsten API
 * @param {String} value
 * @param {String} regex
 * @returns {Boolean}
 */
export const isPostalCodeValid = (value, regex) => {
    if (!regex) return true;
    const pc = value.toUpperCase();
    return new RegExp(`^${regex}$`).test(pc);
};

/**
 * Custom BIC validatie op basis van landcode
 * BIC moet matchen met correcte land
 * @param {String} bic
 * @param {String} landcode
 * @returns {Boolean}
 */
export const isBuitenlandseBicValid = (bic, landcode) => {
    if (!bic || !landcode) {
        return false;
    }

    const reg = new RegExp('^[a-zA-Z]{6}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$', '');
    const bicLand = bic.toUpperCase().slice(4, 6);

    return reg.test(bic) && bicLand === landcode;
};

/**
 * Controleer op lege object
 * @param {Object} obj
 * @returns {Boolean}
 */
export const isObjectEmpty = obj => JSON.stringify(obj) === '{}';

/**
 * Check if given e-mail address contains an invalid domain.
 * Based on list with invalid domain names.
 * @param {Array<string>} list List of invalid domain strings
 * @param {String} email Should be a *valid* e-mail address
 * @returns {Boolean}
 */
export const containsInvalidEmailDomain = (list, email) =>
    list.map(i => i.toUpperCase()).includes(email.toUpperCase().split('@')[1].trim());
