import HttpService from '@/services/HttpService';
const getContent = async (brand) => {
    const { data } = (await HttpService.get(`/clientcontroller/salesfunnelclient/ophalenrenderinfo?propositie=${brand}`));
    if (data.taal === undefined) {
        throw new Error("Couldn't get renderinfo");
    }
    return data;
};
export default {
    getContent
};
