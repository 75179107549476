import DentalInsuranceService from '@/services/DentalInsuranceService';

const createStore = app => {
    const state = {
        vragen: null,
        adviezen: null,
        advies: '',
        pending: false,
        error: false
    };

    const getters = {
        getTvKeuzehulpVragen: state => state.vragen,
        getTvKeuzehulpAdviezen: state => state.adviezen,
        getAdviesContent: (_state, getters) => {
            return getters.getTvKeuzehulpAdviezen.find(el => el.pakket === getters.getAdvies);
        },
        getAdvies: state => state.advies,
        getPending: state => state.pending,
        getError: state => state.error
    };

    const actions = {
        async getVragen({ getters, dispatch }) {
            if (!getters.getTvKeuzehulpVragen) {
                await dispatch('getContent');
            }

            return getters.getTvKeuzehulpVragen;
        },

        async getContent({ rootGetters, commit }) {
            commit('START_REQUEST');

            try {
                const dentalContent = await DentalInsuranceService.getContent(
                    rootGetters['renderInfo/getPropositie']
                );

                commit('SET_DENTAL_CONTENT', dentalContent);
            } catch (error) {
                commit('SET_ERROR', true);
            }

            commit('END_REQUEST');
        },

        setAdvies({ commit }, advies) {
            commit('SET_ADVIES', advies);
        },
        clear({ commit }) {
            commit('clear');
        }
    };

    const mutations = {
        START_REQUEST: state => {
            state.pending = true;
            state.error = false;
        },
        END_REQUEST: state => {
            state.pending = false;
        },
        SET_DENTAL_CONTENT: (state, dentalContent) => {
            state.vragen = dentalContent.vragen;
            state.adviezen = dentalContent.adviezen;
        },
        clear: state => {
            state.vragen = null;
            state.adviezen = null;
        },
        SET_ADVIES: (state, advies) => {
            state.advies = advies;
        },
        SET_ERROR(state, value) {
            state.error = value;
        }
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
};

export const tvKeuzehulpStoragePaths = ['tvkeuzehulp'];

export default app => createStore(app);
