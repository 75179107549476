import { computed } from 'vue';
import {
    EVENT_NO_BV_SELECTED,
    ROUTES,
    ROLE_VERZEKERINGNEMER,
    ROLE_PARTNER,
    ROLE_KIND
} from '@/constants';
import { mapGettersComp } from '@/utils/map-state';

const usePackageChoiceStep = (
    defaultRoute = ROUTES.SALESFUNNEL.PERSOONSGEGEVENS,
    buttonText = {
        default: '',
        policyHolder: '',
        partner: '',
        child: ''
    }
) => {
    const {
        changedToDFRDigitalProduct,
        heeftBasisverzekering,
        getIndexOntbrekendePakketgegevens,
        getAlleVerzekerden
    } = mapGettersComp('users');

    const nextRoutePath = computed(() => {
        if (!heeftBasisverzekering.value) {
            return null;
        }
        return changedToDFRDigitalProduct.value
            ? ROUTES.SALESFUNNEL.GEGEVENS_AANVULLEN
            : defaultRoute;
    });

    const canProceedToNextRoute = computed(() => {
        return !!nextRoutePath.value;
    });

    const buttonProceedText = computed(() => {
        if (getIndexOntbrekendePakketgegevens.value && heeftBasisverzekering.value) {
            switch (getAlleVerzekerden.value[getIndexOntbrekendePakketgegevens.value]?.rol) {
                case ROLE_VERZEKERINGNEMER:
                    return buttonText.policyHolder;
                case ROLE_PARTNER:
                    return buttonText.partner;
                case ROLE_KIND:
                    return buttonText.child;
            }
        }
        return buttonText.default;
    });

    const trackNoBvSelected = () => {
        window.dispatchEvent(new Event(EVENT_NO_BV_SELECTED));
    };

    return {
        nextRoutePath,
        canProceedToNextRoute,
        buttonProceedText,
        trackNoBvSelected
    };
};

export default usePackageChoiceStep;
