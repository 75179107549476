/**
 * Geeft een object van gekozen pakketten terug
 * voor een enkele verzekerde
 * @param {object} pakketkeuzes
 * @param {object} pakketten
 * @returns {object}
 */
const getPakkettenByKeuzes = (pakketkeuzes, pakketten) => {
    const bv = pakketten?.basis?.find(
        pakket => pakket.verkorteOmschrijving === pakketkeuzes?.basis
    );
    const er = bv?.eigenRisicos?.find(pakket => pakket.categorie === pakketkeuzes.eigenrisico);
    const combi = pakketten?.combi?.find(
        pakket => pakket.verkorteOmschrijving === pakketkeuzes?.combi
    );
    const av = pakketten?.aanvullend?.find(
        pakket => pakket.verkorteOmschrijving === pakketkeuzes?.aanvullend
    );
    const dav = pakketten?.dav?.find(pakket => pakket.verkorteOmschrijving === pakketkeuzes?.dav);
    const tv = pakketten?.tand?.find(pakket => pakket.verkorteOmschrijving === pakketkeuzes?.tand);

    return { bv, er, combi, av, dav, tv };
};

/**
 * Geeft totaalbedrag terug gebaseerd op gekozen pakketten
 * voor een enkele verzekerde
 * @param {object} pakketkeuzes
 * @param {object} pakketten
 * @returns {number}
 */
export const getTotaalbedrag = (pakketkeuzes, pakketten) => {
    const { er, combi, av, dav, tv } = getPakkettenByKeuzes(pakketkeuzes, pakketten);

    return [er, combi, av, dav, tv].reduce((curr, p) => curr + (p?.nettoPremie || 0), 0);
};

/**
 * Geeft betaaltermijn korting terug gebaseerd op gekozen pakketten
 * voor een enkele verzekerde
 * @param {object} pakketkeuzes
 * @param {object} pakketten
 * @returns {number}
 */
export const getBetaaltermijnKortingsbedrag = (pakketkeuzes, pakketten) => {
    const { er, combi, av, dav, tv } = getPakkettenByKeuzes(pakketkeuzes, pakketten);

    return [er, combi, av, dav, tv].reduce(
        (curr, p) => curr + (p?.betaalTermijnKortingBedrag || p?.betaalTermijnKorting || 0),
        0
    );
};

/**
 * Geeft collectiviteitskorting terug gebaseerd op gekozen pakketten
 * voor een enkele verzekerde
 * @param {object} pakketkeuzes
 * @param {object} pakketten
 * @returns {number}
 */
export const getCollectiviteitskortingsbedrag = (pakketkeuzes, pakketten) => {
    const { er, combi, av, dav, tv } = getPakkettenByKeuzes(pakketkeuzes, pakketten);

    return [er, combi, av, dav, tv].reduce(
        (curr, p) => curr + (p?.collectiviteitKortingBedrag || p?.collectiviteitKorting || 0),
        0
    );
};

/**
 * Creates object for holding data, pending and error state
 * Used as a sub state for within a Vuex module.
 */
export const createSubState = () => ({
    data: null,
    pending: false,
    error: false
});

/**
 * Get product with highest ranking order from list of suggested packages
 * @param {Array} products
 * @returns {object|null}
 */
export const getLargestProductFromAdvisedPackages = products => {
    let largestProduct = null;

    products.forEach(product => {
        if (largestProduct) {
            if (largestProduct.rangschikking < product.rangschikking) {
                largestProduct = product;
            }
        } else {
            largestProduct = product;
        }
    });

    return largestProduct;
};

/**
 * Map array of vergoedingen to an array of reimbursements
 * @param {Array} vergoedingen
 * @returns {Array|}
 */
export const mapReimbursements = vergoedingen =>
    // vergoedingen?.map(vergoeding => ({
    //     title: vergoeding.titel,
    //     reimbursementText: vergoeding.omschrijving
    // }));
    vergoedingen
        ?.filter(vergoeding => vergoeding.omschrijving !== '-')
        ?.map(vergoeding => ({
            title: vergoeding.titel,
            reimbursementText: vergoeding.omschrijving
        }));
