import { getFieldValue } from '@sitecore-jss/sitecore-jss-vue';
import { UTV_ANDERS } from '@/constants';

const state = {
    dictionary: null,
    rejectReason: null,
    VraagHeeftUTV: null,
    VraagHuidigeUTV: null,
    VraagTandartsGeweest13Maanden: null,
    VraagTandvleesbehandeling: null,
    VraagWortelkanaalbehandeling: null,
    VraagImplantaat: null,
    VraagKroonBrug: null,
    VraagBrugPlakbrug: null,
    VraagVullingen: null,
    VraagKunstgebit: null,
    VraagVerwachtBehandelingen: null,
    VraagVerwachtTandvleesbehandeling: null,
    VraagVerwachtWortelkanaalbehandeling: null,
    VraagVerwachtImplantaat: null,
    VraagVerwachtKroonBrug: null,
    VraagVerwachtBrugPlakbrug: null,
    VraagVerwachtVullingen: null,
    VraagVerwachtKunstgebit: null,
    VraagBeugel: null,
    VraagMistTandenOfKiezen: null,
    VraagVervangenTanden: null,
    VraagVervangenDoorKunstgebitOfProthese: null,
    Vraag1OfMeerImplantaten: null,
    VraagImplantatenOuderDan10Jaar: null,
    VraagKroonBrugtussendeelInlay: null,
    VraagAantalKronen: null,
    VraagAantalKronenOuderDan10Jaar: null,
    VraagOrthodontischeBehandeling: null,
    VraagMeerdereWortelkanaalbehandelingen: null,
    VraagMeerdereKronen: null,
    VraagTandvleesbehandelingGehad: null,
    VraagAanvullendeTandvleesbehandelingen: null,
    VraagToestemmingAutomatischeBeoordeling: null
};

const getters = {
    allQuestions: state => Object.keys(state).filter(property => property.startsWith('Vraag')),
    Ja: state => getFieldValue(state.dictionary, 'Ja'),
    Nee: state => getFieldValue(state.dictionary, 'Nee'),
    Anders: state => getFieldValue(state.dictionary, 'Anders'),
    Aantal0Of1: state => getFieldValue(state.dictionary, 'Aantal0Of1'),
    AantalMeerDan1: state => getFieldValue(state.dictionary, 'AantalMeerDan1'),
    Aantal9OfMinder: state => getFieldValue(state.dictionary, 'Aantal9OfMinder'),
    Aantal10OfMeer: state => getFieldValue(state.dictionary, 'Aantal10OfMeer'),
    VraagBeugelTonen: state => getFieldValue(state.dictionary, 'VraagBeugelTonen'),
    VraagOrthodontischeBehandelingTonen: state =>
        getFieldValue(state.dictionary, 'VraagOrthodontischeBehandelingTonen'),

    /** deel 1 */
    deel1Answers: (state, getters) => {
        const { VraagHeeftUTV, VraagHuidigeUTV, VraagTandartsGeweest13Maanden } = state;
        if (getters.heeftUTV) {
            return [VraagHeeftUTV, VraagHuidigeUTV, VraagTandartsGeweest13Maanden];
        }
        return [VraagTandartsGeweest13Maanden];
    },
    questionsGeneralComplete: (state, getters) => isComplete(getters, ...getters.deel1Answers),
    questionsGeneralRejected: (state, getters) => {
        if (getters.heeftOvereenkomendeVerzekering) {
            return false;
        }
        if (
            getters.questionsGeneralComplete &&
            getters.deel1Answers.filter(el => el?.value === getters.Nee || el?.value === null)
                ?.length > 0
        ) {
            return true;
        }
        return false;
    },

    /** deel 2 */
    deel2Answers: state => {
        const {
            VraagTandvleesbehandeling,
            VraagWortelkanaalbehandeling,
            VraagImplantaat,
            VraagKroonBrug,
            VraagBrugPlakbrug,
            VraagVullingen,
            VraagKunstgebit
        } = state;
        return [
            VraagTandvleesbehandeling,
            VraagWortelkanaalbehandeling,
            VraagImplantaat,
            VraagKroonBrug,
            VraagBrugPlakbrug,
            VraagVullingen,
            VraagKunstgebit
        ];
    },
    questionsCurrentTreatmentsComplete: (state, getters) =>
        isComplete(getters, ...getters.deel2Answers),
    questionsCurrentTreatmentsRejected: (state, getters) => {
        if (getters.heeftOvereenkomendeVerzekering) {
            return false;
        }
        if (
            getters.questionsCurrentTreatmentsComplete &&
            getters.deel2Answers.filter(el => el?.value === getters.Ja)?.length > 0
        ) {
            return true;
        }
        return false;
    },

    /** deel 3 */
    deel3Answers: state => {
        const {
            VraagVerwachtTandvleesbehandeling,
            VraagVerwachtWortelkanaalbehandeling,
            VraagVerwachtImplantaat,
            VraagVerwachtKroonBrug,
            VraagVerwachtBrugPlakbrug,
            VraagVerwachtVullingen,
            VraagVerwachtKunstgebit
        } = state;
        return [
            VraagVerwachtTandvleesbehandeling,
            VraagVerwachtWortelkanaalbehandeling,
            VraagVerwachtImplantaat,
            VraagVerwachtKroonBrug,
            VraagVerwachtBrugPlakbrug,
            VraagVerwachtVullingen,
            VraagVerwachtKunstgebit
        ];
    },
    questionsExpectedTreatmentsComplete: (state, getters) =>
        isComplete(getters, ...getters.deel3Answers),
    questionsExpectedTreatmentsRejected: (state, getters) => {
        if (getters.heeftOvereenkomendeVerzekering) {
            return false;
        }
        if (
            getters.questionsExpectedTreatmentsComplete &&
            getters.deel3Answers.filter(el => el?.value === getters.Ja)?.length > 0
        ) {
            return true;
        }
        return false;
    },

    /** deel 4 */
    deel4Answers: state => {
        const {
            VraagBeugel,
            VraagMistTandenOfKiezen,
            VraagVervangenTanden,
            VraagVervangenDoorKunstgebitOfProthese,
            Vraag1OfMeerImplantaten,
            VraagImplantatenOuderDan10Jaar,
            VraagKroonBrugtussendeelInlay,
            VraagAantalKronen,
            VraagAantalKronenOuderDan10Jaar,
            VraagOrthodontischeBehandeling,
            VraagMeerdereWortelkanaalbehandelingen,
            VraagMeerdereKronen,
            VraagTandvleesbehandelingGehad,
            VraagAanvullendeTandvleesbehandelingen
        } = state;
        return [
            VraagBeugel,
            VraagMistTandenOfKiezen,
            VraagVervangenTanden,
            VraagVervangenDoorKunstgebitOfProthese,
            Vraag1OfMeerImplantaten,
            VraagImplantatenOuderDan10Jaar,
            VraagKroonBrugtussendeelInlay,
            VraagAantalKronen,
            VraagAantalKronenOuderDan10Jaar,
            VraagOrthodontischeBehandeling,
            VraagMeerdereWortelkanaalbehandelingen,
            VraagMeerdereKronen,
            VraagTandvleesbehandelingGehad,
            VraagAanvullendeTandvleesbehandelingen
        ];
    },
    deel4Complete: (state, getters) => {
        if (getters.heeftOvereenkomendeVerzekering) {
            return true;
        }
        if (
            getters.rejectReason ||
            state.VraagTandvleesbehandelingGehad?.value === getters.Nee ||
            state.VraagAanvullendeTandvleesbehandelingen?.value === getters.Nee
        )
            return true;
        return false;
    },
    deel4Rejected: (state, getters) => {
        if (getters.heeftOvereenkomendeVerzekering) {
            return false;
        }
        if (
            getters.rejectReason ||
            state.VraagVervangenTanden?.value === getters.Nee ||
            state.VraagVervangenDoorKunstgebitOfProthese?.value === getters.Ja ||
            state.VraagImplantatenOuderDan10Jaar?.value === getters.Ja ||
            state.VraagAantalKronen?.value === getters.Aantal10OfMeer ||
            state.VraagAantalKronenOuderDan10Jaar?.value === getters.AantalMeerDan1 ||
            state.VraagMeerdereKronen?.value === getters.Nee ||
            state.VraagAanvullendeTandvleesbehandelingen?.value === getters.Ja
        )
            return true;
        return false;
    },

    /* Indien gebruiker overeenkomende TV heeft gekozen */
    heeftOvereenkomendeVerzekering: state =>
        state.VraagHuidigeUTV !== UTV_ANDERS && state.VraagHuidigeUTV !== null,
    heeftGekozenOvereenkomendeVerzekering: state => state.VraagHuidigeUTV !== null,
    heeftUTV: (state, getters) => state.VraagHeeftUTV?.value === getters.Ja,
    /** deel 4: specifieke checks voor individuele vragen */
    showVraagBeugel: (state, getters) => getters.VraagBeugelTonen, //niet bij defriesland tonen
    showMeldingOrthodontieAV: (state, getters) => state.VraagBeugel?.value === getters.Ja, //niet bij defriesland tonen
    showVraagMistTandenOfKiezen: (state, getters) =>
        getters.VraagBeugelTonen === false ||
        state.VraagBeugel?.value === getters.Ja ||
        state.VraagBeugel?.value === getters.Nee, //tonen zowel bij antwoord ja en nee, bij ja zie je voor de vraag een alert. :-)
    showVraagVervangenTanden: (state, getters) =>
        state.VraagMistTandenOfKiezen?.value === getters.Ja,
    showVraagVervangenDoorKunstgebitOfProthese: (state, getters) =>
        state.VraagMistTandenOfKiezen?.value === getters.Nee ||
        state.VraagVervangenTanden?.value === getters.Ja,
    showVraag1OfMeerImplantaten: (state, getters) =>
        state.VraagVervangenDoorKunstgebitOfProthese?.value === getters.Nee,
    showVraagImplantatenOuderDan10Jaar: (state, getters) =>
        state.Vraag1OfMeerImplantaten?.value === getters.Ja,
    showVraagKroonBrugtussendeelInlay: (state, getters) =>
        state.Vraag1OfMeerImplantaten?.value === getters.Nee ||
        state.VraagImplantatenOuderDan10Jaar?.value === getters.Nee,
    showVraagAantalKronen: (state, getters) =>
        state.VraagKroonBrugtussendeelInlay?.value === getters.Ja,
    showVraagAantalKronenOuderDan10Jaar: (state, getters) =>
        state.VraagAantalKronen?.value === getters.Aantal9OfMinder,
    showVraagOrthodontischeBehandeling: (state, getters) =>
        getters.VraagOrthodontischeBehandelingTonen === true && // voor nu alleen bij defriesland tonen
        (state.VraagKroonBrugtussendeelInlay?.value === getters.Nee ||
            state.VraagAantalKronenOuderDan10Jaar?.value === getters.Nee),
    showVraagMeerdereWortelkanaalbehandelingen: (state, getters) =>
        state.VraagKroonBrugtussendeelInlay?.value === getters.Nee ||
        state.VraagAantalKronenOuderDan10Jaar?.value === getters.Aantal0Of1 ||
        state.VraagOrthodontischeBehandeling?.value === getters.Nee,
    showVraagMeerdereKronen: (state, getters) =>
        state.VraagMeerdereWortelkanaalbehandelingen?.value === getters.Ja,
    showVraagTandvleesbehandelingGehad: (state, getters) =>
        state.VraagMeerdereWortelkanaalbehandelingen?.value === getters.Nee ||
        (state.VraagMeerdereWortelkanaalbehandelingen?.value === getters.Ja &&
            state.VraagMeerdereKronen?.value === getters.Ja),
    showVraagAanvullendeTandvleesbehandelingen: (state, getters) =>
        state.VraagTandvleesbehandelingGehad?.value === getters.Ja,

    /** Reden van afwijzing */
    rejectReason: (state, getters) => {
        if (getters.questionsGeneralRejected)
            return getFieldValue(state.dictionary, 'Afwijzing13MaandenGeenControle');
        if (getters.questionsCurrentTreatmentsRejected)
            return getFieldValue(state.dictionary, 'AfwijzingVerwachtUitgebreideBehandeling');
        if (getters.questionsExpectedTreatmentsRejected)
            return getFieldValue(state.dictionary, 'AfwijzingVerwachtUitgebreideBehandeling');
        if (state.VraagVervangenDoorKunstgebitOfProthese?.value === getters.Ja)
            return getFieldValue(state.dictionary, 'AfwijzingKunstgebit');
        if (
            state.VraagMistTandenOfKiezen?.value === getters.Ja &&
            state.VraagVervangenTanden?.value === getters.Nee
        )
            return getFieldValue(state.dictionary, 'AfwijzingTandenOntbrekend4OfMeer');
        if (state.VraagVervangenTanden?.value === getters.Nee)
            return getFieldValue(state.dictionary, 'AfwijzingTandenOntbrekendTeVeel');
        if (state.VraagImplantatenOuderDan10Jaar?.value === getters.Ja)
            return getFieldValue(state.dictionary, 'AfwijzingImplantaten10JaarOfMeer');
        if (state.VraagAantalKronen?.value === getters.Aantal10OfMeer)
            return getFieldValue(state.dictionary, 'AfwijzingKronen10OfMeer');
        if (state.VraagAantalKronenOuderDan10Jaar?.value === getters.AantalMeerDan1)
            return getFieldValue(state.dictionary, 'AfwijzingBrugKronenInlayFacing10JaarOfMeer');
        if (state.VraagOrthodontischeBehandeling?.value === getters.Ja)
            return getFieldValue(state.dictionary, 'AfwijzingOrthodontischeBehandeling'); //alleen geval bij defriesland
        if (state.VraagMeerdereKronen?.value === getters.Nee)
            return getFieldValue(state.dictionary, 'AfwijzingWortelkanaalZonderKroon');
        if (state.VraagAanvullendeTandvleesbehandelingen?.value === getters.Ja)
            return getFieldValue(state.dictionary, 'AfwijzingVerwachtTandvleesBehandeling');
        return null;
    }
};

const actions = {
    addToUser({ dispatch, commit, state, getters, rootGetters }) {
        const medischevragen = {};
        const rejectReason = getters.rejectReason;
        const medischeAcceptatiePakketten = rootGetters['pakketten/getProducts'].tand
            .filter(pakket => pakket.medischeAcceptatieNodig === true)
            .map(function (pakket) {
                return pakket.verkorteOmschrijving;
            });

        getters.allQuestions.forEach(function (question) {
            medischevragen[question] = state[question];
        });
        dispatch('users/setMedischeVragen', medischevragen, { root: true });

        // Alleen pakketten disablen wanneer er een afwijsreden is.
        if (rejectReason) {
            dispatch('users/setRejectReason', rejectReason, { root: true });
            dispatch('pakketten/setDisabledTandPakketten', medischeAcceptatiePakketten, {
                root: true
            });
        }

        commit('RESET_STATE');
    },
    setVraagHeeftUTV({ commit }, value) {
        commit('SET_VraagHeeftUTV', value);
    },
    setVraagHuidigeUTV({ commit }, value) {
        commit('SET_VraagHuidigeUTV', value);
    },
    setVraagTandartsGeweest13Maanden({ commit }, value) {
        commit('SET_VraagTandartsGeweest13Maanden', value);
    },
    clear({ commit }) {
        commit('RESET_STATE');
    }
};

const mutations = {
    SET_dictionary(state, value) {
        state.dictionary = value;
    },

    /** deel 1 */
    SET_VraagHeeftUTV(state, value) {
        state.VraagHeeftUTV = value;
    },
    SET_VraagHuidigeUTV(state, value) {
        state.VraagHuidigeUTV = value;
    },
    SET_VraagTandartsGeweest13Maanden(state, value) {
        state.VraagTandartsGeweest13Maanden = value;
    },
    SET_VraagTandvleesbehandeling(state, value) {
        state.VraagTandvleesbehandeling = value;
    },
    SET_VraagWortelkanaalbehandeling(state, value) {
        state.VraagWortelkanaalbehandeling = value;
    },
    SET_VraagImplantaat(state, value) {
        state.VraagImplantaat = value;
    },
    SET_VraagKroonBrug(state, value) {
        state.VraagKroonBrug = value;
    },
    SET_VraagBrugPlakbrug(state, value) {
        state.VraagBrugPlakbrug = value;
    },
    SET_VraagVullingen(state, value) {
        state.VraagVullingen = value;
    },
    SET_VraagKunstgebit(state, value) {
        state.VraagKunstgebit = value;
    },
    SET_VraagVerwachtBehandelingen(state, value) {
        state.VraagVerwachtBehandelingen = value;
    },
    SET_VraagVerwachtTandvleesbehandeling(state, value) {
        state.VraagVerwachtTandvleesbehandeling = value;
    },
    SET_VraagVerwachtWortelkanaalbehandeling(state, value) {
        state.VraagVerwachtWortelkanaalbehandeling = value;
    },
    SET_VraagVerwachtImplantaat(state, value) {
        state.VraagVerwachtImplantaat = value;
    },
    SET_VraagVerwachtKroonBrug(state, value) {
        state.VraagVerwachtKroonBrug = value;
    },
    SET_VraagVerwachtBrugPlakbrug(state, value) {
        state.VraagVerwachtBrugPlakbrug = value;
    },
    SET_VraagVerwachtVullingen(state, value) {
        state.VraagVerwachtVullingen = value;
    },
    SET_VraagVerwachtKunstgebit(state, value) {
        state.VraagVerwachtKunstgebit = value;
    },
    SET_VraagBeugel(state, value) {
        state.VraagBeugel = value;
        state.VraagMistTandenOfKiezen = null;
        state.VraagVervangenTanden = null;
        state.VraagVervangenDoorKunstgebitOfProthese = null;
        state.Vraag1OfMeerImplantaten = null;
        state.VraagImplantatenOuderDan10Jaar = null;
        state.VraagKroonBrugtussendeelInlay = null;
        state.VraagAantalKronen = null;
        state.VraagAantalKronenOuderDan10Jaar = null;
        state.VraagOrthodontischeBehandeling = null;
        state.VraagMeerdereWortelkanaalbehandelingen = null;
        state.VraagMeerdereKronen = null;
        state.VraagTandvleesbehandelingGehad = null;
        state.VraagAanvullendeTandvleesbehandelingen = null;
    },
    SET_VraagMistTandenOfKiezen(state, value) {
        state.VraagMistTandenOfKiezen = value;
        state.VraagVervangenTanden = null;
        state.VraagVervangenDoorKunstgebitOfProthese = null;
        state.Vraag1OfMeerImplantaten = null;
        state.VraagImplantatenOuderDan10Jaar = null;
        state.VraagKroonBrugtussendeelInlay = null;
        state.VraagAantalKronen = null;
        state.VraagAantalKronenOuderDan10Jaar = null;
        state.VraagOrthodontischeBehandeling = null;
        state.VraagMeerdereWortelkanaalbehandelingen = null;
        state.VraagMeerdereKronen = null;
        state.VraagTandvleesbehandelingGehad = null;
        state.VraagAanvullendeTandvleesbehandelingen = null;
    },
    SET_VraagVervangenTanden(state, value) {
        state.VraagVervangenTanden = value;
        state.VraagVervangenDoorKunstgebitOfProthese = null;
        state.Vraag1OfMeerImplantaten = null;
        state.VraagImplantatenOuderDan10Jaar = null;
        state.VraagKroonBrugtussendeelInlay = null;
        state.VraagAantalKronen = null;
        state.VraagAantalKronenOuderDan10Jaar = null;
        state.VraagOrthodontischeBehandeling = null;
        state.VraagMeerdereWortelkanaalbehandelingen = null;
        state.VraagMeerdereKronen = null;
        state.VraagTandvleesbehandelingGehad = null;
        state.VraagAanvullendeTandvleesbehandelingen = null;
    },
    SET_VraagVervangenDoorKunstgebitOfProthese(state, value) {
        state.VraagVervangenDoorKunstgebitOfProthese = value;
        state.Vraag1OfMeerImplantaten = null;
        state.VraagImplantatenOuderDan10Jaar = null;
        state.VraagKroonBrugtussendeelInlay = null;
        state.VraagAantalKronen = null;
        state.VraagAantalKronenOuderDan10Jaar = null;
        state.VraagOrthodontischeBehandeling = null;
        state.VraagMeerdereWortelkanaalbehandelingen = null;
        state.VraagMeerdereKronen = null;
        state.VraagTandvleesbehandelingGehad = null;
        state.VraagAanvullendeTandvleesbehandelingen = null;
    },
    SET_Vraag1OfMeerImplantaten(state, value) {
        state.Vraag1OfMeerImplantaten = value;
        state.VraagImplantatenOuderDan10Jaar = null;
        state.VraagKroonBrugtussendeelInlay = null;
        state.VraagAantalKronen = null;
        state.VraagAantalKronenOuderDan10Jaar = null;
        state.VraagOrthodontischeBehandeling = null;
        state.VraagMeerdereWortelkanaalbehandelingen = null;
        state.VraagMeerdereKronen = null;
        state.VraagTandvleesbehandelingGehad = null;
        state.VraagAanvullendeTandvleesbehandelingen = null;
    },
    SET_VraagImplantatenOuderDan10Jaar(state, value) {
        state.VraagImplantatenOuderDan10Jaar = value;
        state.VraagKroonBrugtussendeelInlay = null;
        state.VraagAantalKronen = null;
        state.VraagAantalKronenOuderDan10Jaar = null;
        state.VraagOrthodontischeBehandeling = null;
        state.VraagMeerdereWortelkanaalbehandelingen = null;
        state.VraagMeerdereKronen = null;
        state.VraagTandvleesbehandelingGehad = null;
        state.VraagAanvullendeTandvleesbehandelingen = null;
    },
    SET_VraagKroonBrugtussendeelInlay(state, value) {
        state.VraagKroonBrugtussendeelInlay = value;
        state.VraagAantalKronen = null;
        state.VraagAantalKronenOuderDan10Jaar = null;
        state.VraagOrthodontischeBehandeling = null;
        state.VraagMeerdereWortelkanaalbehandelingen = null;
        state.VraagMeerdereKronen = null;
        state.VraagTandvleesbehandelingGehad = null;
        state.VraagAanvullendeTandvleesbehandelingen = null;
    },
    SET_VraagAantalKronen(state, value) {
        state.VraagAantalKronen = value;
        state.VraagAantalKronenOuderDan10Jaar = null;
        state.VraagOrthodontischeBehandeling = null;
        state.VraagMeerdereWortelkanaalbehandelingen = null;
        state.VraagMeerdereKronen = null;
        state.VraagTandvleesbehandelingGehad = null;
        state.VraagAanvullendeTandvleesbehandelingen = null;
    },
    SET_VraagAantalKronenOuderDan10Jaar(state, value) {
        state.VraagAantalKronenOuderDan10Jaar = value;
        state.VraagOrthodontischeBehandeling = null;
        state.VraagMeerdereWortelkanaalbehandelingen = null;
        state.VraagMeerdereKronen = null;
        state.VraagTandvleesbehandelingGehad = null;
        state.VraagAanvullendeTandvleesbehandelingen = null;
    },
    SET_VraagOrthodontischeBehandeling(state, value) {
        state.VraagOrthodontischeBehandeling = value;
        state.VraagMeerdereWortelkanaalbehandelingen = null;
        state.VraagMeerdereKronen = null;
        state.VraagTandvleesbehandelingGehad = null;
        state.VraagAanvullendeTandvleesbehandelingen = null;
    },
    SET_VraagMeerdereWortelkanaalbehandelingen(state, value) {
        state.VraagMeerdereWortelkanaalbehandelingen = value;
        state.VraagMeerdereKronen = null;
        state.VraagTandvleesbehandelingGehad = null;
        state.VraagAanvullendeTandvleesbehandelingen = null;
    },
    SET_VraagMeerdereKronen(state, value) {
        state.VraagMeerdereKronen = value;
        state.VraagTandvleesbehandelingGehad = null;
        state.VraagAanvullendeTandvleesbehandelingen = null;
    },
    SET_VraagTandvleesbehandelingGehad(state, value) {
        state.VraagTandvleesbehandelingGehad = value;
        state.VraagAanvullendeTandvleesbehandelingen = null;
    },
    SET_VraagAanvullendeTandvleesbehandelingen(state, value) {
        state.VraagAanvullendeTandvleesbehandelingen = value;
    },
    SET_VraagToestemmingAutomatischeBeoordeling(state, value) {
        state.VraagToestemmingAutomatischeBeoordeling = value;
    },
    RESET_STATE(state) {
        const questions = Object.keys(state).filter(property => property.startsWith('Vraag'));
        questions.forEach(question => {
            state[question] = null;
        });
    }
};

export const medicalQuestionsStoragePaths = ['medischevragen'];

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

function isComplete(getters, ...args) {
    if (getters.heeftOvereenkomendeVerzekering) {
        return true;
    }
    // if at least one element is null, the provided part is not complete.
    if (args.filter(el => el === null)?.length > 0) {
        return false;
    }
    return true;
}
