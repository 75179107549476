// Import necessary libraries and utility functions
import { watch, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { onLCP, onFID, onCLS } from 'web-vitals/attribution';
import { clearDotsnCommas } from '@/utils/formatters';
import {
    checkCookiePermission,
    resetTags,
    setPageStructure,
    loadTags,
    addLocalTagProperty,
    publishCWVEvent
} from '@/utils/relay42';

export const useTrackingApp = () => {
    // Access Vuex store, Vue Router and app context
    const store = useStore();
    const route = useRoute();
    const { appContext } = inject('jss');

    // Declare computed properties from the Vuex store and app context
    const isProspectFlow = computed(() => store.getters['renderInfo/isProspectFlow']);
    const isStappenplan = computed(() => store.getters['renderInfo/isStappenplan']);
    const getCollNr = computed(() => store.getters['prefill/getCollectiviteitnummer']);
    const transactieId = computed(() => store.getters['users/getTransactieId']);
    const aanvraagGegevens = computed(() => store.getters['users/getAanvraagGegevens']);
    const propositie = computed(() => appContext().propositie);

    // Watch for route changes and set tracking accordingly
    watch(route, ({ params: { sitecoreRoute } }) => {
        ctx.setTracking(sitecoreRoute[0]);
    });

    // Set tracking based on the current sitecore route
    const setTracking = sitecoreRoute => {
        const currentUrlHost = window.location.hostname.toString();
        const currentRoute = sitecoreRoute === 'start' ? '' : sitecoreRoute;
        const propositieNaam = ctx.getPropositieNaam(propositie.value);
        const prospect = ['zorgverzekering', propositieNaam, 'premie-berekenen'];
        const wijzigen = ['mijn', 'verzekering', propositieNaam, 'wijzigen'];
        const stappenplan = ['zorgverzekering', propositieNaam, 'stappenplan'];
        const relay42Tags = [
            currentUrlHost.includes('zilverenkruis') ? 'consumenten' : '',
            ...(isProspectFlow.value ? prospect : isStappenplan.value ? stappenplan : wijzigen),
            currentRoute
        ]
            .filter(n => n)
            .join('|');

        try {
            ctx.setupTracking(currentRoute, relay42Tags);
        } catch (error) {
            console.log('relay42 errors', error);
        }
    };

    // Initialize tracking with the given Relay42 tags
    const setupTracking = (currentRoute, relay42Tags) => {
        if (checkCookiePermission()) {
            resetTags();
            setPageStructure(relay42Tags);

            ctx.setupCWVEvents();

            if (ctx.isWelkomRoute(currentRoute)) {
                ctx.setAffiliateInfo();
            } else {
                ctx.clearAffiliateInfo();
            }

            loadTags();
        }
    };

    // Set up Core Web Vitals event listeners
    const setupCWVEvents = () => {
        onCLS(publishCWVEvent, { reportAllChanges: true });
        onFID(publishCWVEvent);
        onLCP(publishCWVEvent);
    };

    // Check if the sitcore route is a "welkom" route
    const isWelkomRoute = currentRoute => {
        return currentRoute?.includes('welkom');
    };

    // Get the propositie naam based on the given propositie code
    const getPropositieNaam = propositie => {
        const propositieLijst = {
            BAP: '',
            DPR: 'ziezo',
            AON: 'aon'
        };

        return propositieLijst[propositie];
    };

    // Set the affiliate information by adding properties to the local tag storage.
    const setAffiliateInfo = () => {
        addLocalTagProperty('collnr', getCollNr.value ? getCollNr.value : '');
        addLocalTagProperty('transid', transactieId.value);
        addLocalTagProperty('verzekerden', aanvraagGegevens.value.aantalVerzekerden);
        addLocalTagProperty('maandpremie', clearDotsnCommas(aanvraagGegevens.value.gezinsPremie));
        addLocalTagProperty('paymentPeriod', aanvraagGegevens.value.betaalTermijn);
        addLocalTagProperty('verzekerdenInfo', aanvraagGegevens.value.verzekerdeInfo);
    };

    // Clear the affiliate information by resetting properties in the local tag storage.
    const clearAffiliateInfo = () => {
        addLocalTagProperty('collnr', '');
        addLocalTagProperty('transid', '');
        addLocalTagProperty('verzekerden', '');
        addLocalTagProperty('maandpremie', '');
        addLocalTagProperty('paymentPeriod', '');
        addLocalTagProperty('verzekerdenInfo', '');
    };

    // injectable methods ctx for testing
    const ctx = {
        setTracking,
        setupTracking,
        setupCWVEvents,
        isWelkomRoute,
        getPropositieNaam,
        setAffiliateInfo,
        clearAffiliateInfo
    };

    // return for testing
    return { isProspectFlow, getCollNr, transactieId, aanvraagGegevens, propositie, ctx };
};
