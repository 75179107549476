import { enableDebug, handleEditorAnchors } from '@sitecore-jss/sitecore-jss-vue';
import { createApp } from './createApp';
import { createVueAppContext } from './appContext';
if (process.env.VUE_APP_DEBUG) {
    enableDebug(process.env.VUE_APP_DEBUG);
}
/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide the window.__JSS_STATE__ object
  for us to acquire the initial state to run with on the client.

  This enables us to skip a network request to load up the layout data.

  SSR is initiated from /server/server.js.
*/
let __JSS_STATE__ = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');
if (ssrRawJson) {
    __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}
// render the app
const initialState = __JSS_STATE__ || null;
const appContext = createVueAppContext('salesfunnel-app');
const { app } = createApp(appContext, initialState);
app.mount('#salesfunnel-app');
handleEditorAnchors();
