/**
 * Geeft laatste segment van URL terug
 * Rekening houden met trailing slash
 * @param {String} url
 * @returns {String}
 */
export const getLastUrlSegment = (url = '') => {
    let segment = '/';

    if (url.endsWith('/')) {
        const split = url.split('/');
        segment += split[split.length - 2];
    } else {
        segment += url.split('/').pop();
    }

    return segment;
};

/**
 * Verwijder een query parameter uit de huidige URL
 * Let op: huidige adres URL wordt vervangen middels `history.replaceState`
 * @param {string} param
 */
export const removeParamFromCurrentUrl = param => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    if (!params.get(param)) return;

    params.delete(param);
    const newQueryString = params.toString() ? `?${params.toString()}` : '';
    const newUrl = url.href.split('?')[0] + newQueryString;

    history.replaceState({}, null, newUrl);
};

/**
 * Return a full URL based on given 'relative' URL and current origin.
 * @param {string} relativeUrl
 * @param {string} [customBaseUrl]
 */
export const getFullURL = (relativeUrl, customBaseUrl) => {
    try {
        let baseUrl = customBaseUrl ?? window.location.origin;

        if (baseUrl.includes('localhost:3000')) {
            baseUrl = 'https://zka.zdn.local';
        }

        const url = new URL(relativeUrl, baseUrl);

        return url.toString();
    } catch (error) {
        return relativeUrl;
    }
};
