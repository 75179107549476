export const sortByProp = (array, prop, descending) => {
    return [...array].sort((a, b) => descending
        ? a[prop] > b[prop]
            ? -1
            : a[prop] < b[prop]
                ? 1
                : 0
        : a[prop] < b[prop]
            ? -1
            : a[prop] > b[prop]
                ? 1
                : 0);
};
