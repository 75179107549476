const embeddedApp = {
    // default state
    state: {
        appContext: {}
    }
};

// Vue plugins must export a function named 'install'
function install(app, { appContext }) {
    app.config.globalProperties.$jss = {
        // there may be other JSS plugins installed, merge existing properties
        ...(app.config.globalProperties.$jss || {}),
        embeddedApp,
        appContext() {
            // this is intended only as a convenience function for easier access to the app context.
            return embeddedApp.state.appContext;
        }
    };
    // initialize the app context from element
    embeddedApp.state.appContext = appContext;
}

export default { install };
