/**
 * This module exports utility functions to interact with Relay42, a marketing data platform.
 * Functions check for the availability of Relay42, manage cookie preferences, handle tags,
 * and publish Custom Web Vitals (CWV) events.
 */

/**
 * Check if Relay42 is available on the window object.
 *
 * @returns {boolean} True if Relay42 is available, false otherwise.
 */
export const isRelay42Available = () => {
    return typeof window._st !== 'undefined' && typeof window._st.cookiepermission !== 'undefined';
};

/**
 * Check if the user has given permission for at least two cookies.
 *
 * @param {number} level Level of permissions, default 2.
 * @returns {boolean} True if permission granted, false otherwise.
 */
export const checkCookiePermission = (level = 2) => {
    if (!isRelay42Available()) return false;
    const preferences = window._st.cookiepermission.getCookiePreferences();
    return Object.values(preferences).filter(p => p).length >= level;
};

/**
 * Reset tags in Relay42 if it is available.
 */
export const resetTags = () => {
    if (!isRelay42Available()) return;
    window._st('resetTags');
};

/**
 * Set the page structure in Relay42 with the given tags.
 *
 * @param {object} r42Tags An object containing Relay42 tags.
 */
export const setPageStructure = r42Tags => {
    if (!isRelay42Available()) return;
    window._st('setPageStructure', r42Tags);
};

/**
 * Load tags from Relay42 if it is available.
 */
export const loadTags = () => {
    if (!isRelay42Available()) return;
    window._st('loadTags');
};

/**
 * Add a local tag property with the given property name and value.
 *
 * @param {string} property The property name.
 * @param {*} value The value to set for the property.
 */
export const addLocalTagProperty = (property, value) => {
    if (!isRelay42Available()) return;
    window._st('addLocalTagProperty', property, value);
};

/**
 * Publish a Custom Web Vitals (CWV) event with the given metric.
 *
 * @param {object} metric An object containing metric data.
 */
export const publishCWVEvent = metric => {
    if (!isRelay42Available()) return;
    window._st.event.publish('CWV', metric.name, metric);
};

/**
 * Publish a event with the given formField.
 *
 * @param {string} fieldName Name of the field.
 * @param {string} fieldType Type of the field.
 * @param {string} interactionType Type of interaction, ex.: enter/exit/change.
 */
export const publishFieldEvent = (fieldName, fieldType, interactionType) => {
    if (!isRelay42Available()) return;
    window._st.event.publish('Forms', interactionType, {
        name: fieldName,
        type: fieldType
    });
};

/**
 * Publish a event with the given formField.
 *
 * @param {string} interactionType Type of interaction, ex.: positive/negative/message.
 */
export const publishVideoFeedbackEvent = (interactionType, feedbackText = '') => {
    if (!isRelay42Available()) return;

    if (interactionType === ('positive' || 'negative')) {
        window._st.tracking.sendEngagement('Pageview', {
            customview: interactionType === 'positive' ? 'Nuttig_Ja' : 'Nuttig_Nee',
            url: window.location.href,
            time: +new Date()
        });
        // ga('send', 'event', 'Nuttig', 'Klik', interactionType === 'positive' ? 'Ja' : 'Nee');
        // gtag('event', 'click', {
        //     event_category: 'Nuttig',
        //     event_label: interactionType === 'positive' ? 'Ja' : 'Nee'
        // });
    }

    if (interactionType === 'message') {
        window._st.tracking.sendEngagement('Pageview', {
            opm_usb: feedbackText,
            url: window.location.href,
            time: +new Date()
        });
    }
};
