import HttpService from '@/services/HttpService';
const getContent = async (brand) => {
    const { data } = (await HttpService.post('/clientcontroller/salesfunnelclient/ophalentvkeuzehulpcontent', {
        Propositie: brand
    }));
    if (data.isSucces === false) {
        throw new Error('Clientcontroller returned isSucces -> false');
    }
    return data.tvKeuzehulpResponse;
};
export default {
    getContent
};
