import { APP_TYPE_PROSPECT, APP_TYPE_STAPPENPLAN, APP_TYPE_WIJZIGEN } from '@/constants';
import RenderInfoService from '@/services/RenderInfoService';
import { createVueAppContext } from '@/appContext';

const createStore = () => {
    const state = {
        brand: null,
        appType: null,
        isCampaignPeriod: null,
        showProvisionInfo: null,
        showDmr: null,
        language: null,
        campaignYear: null,
        daysUntilStartNewCampaignYear: null,
        appContext: createVueAppContext()
    };

    const getters = {
        getAppType: () => state.appContext.appType || APP_TYPE_WIJZIGEN,
        isProspectFlow: (_state, getters) => getters.getAppType === APP_TYPE_PROSPECT,
        isWijzigenFlow: (_state, getters) => getters.getAppType === APP_TYPE_WIJZIGEN,
        isStappenplan: (_state, getters) => getters.getAppType === APP_TYPE_STAPPENPLAN,
        getAantalDagenTotStartPremiejaar: state => state.daysUntilStartNewCampaignYear,
        getIsCampagneperiode: state => state.isCampaignPeriod,
        getPremiejaar: state => state.campaignYear,
        getDmrTonen: state => state.showDmr,
        showProvisionInfo: state => state.showProvisionInfo,
        getSiteName: state => state.appContext.siteName,
        getAppSiteName: state => state.appContext.appSiteName,
        getPropositie: state => state.appContext.propositie,
        getTaal: state => state.language,
        getPropositieFromState: state => state.brand,
        getAppTypeFromState: state => state.appType
    };

    const actions = {
        updateRenderInfo: ({ commit }, renderInfo) => {
            commit('SET_RENDER_INFO', renderInfo);
        },
        setAppType: ({ commit }, appType) => {
            commit('SET_APP_TYPE', appType);
        },
        setBrand: ({ commit }, brand) => {
            commit('SET_BRAND', brand);
        },
        setLanguage: ({ commit }, value) => {
            commit('SET_LANGUAGE', value);
        },
        async initializeRenderInfo({ getters, commit }) {
            try {
                const data = await RenderInfoService.getContent(getters.getPropositie);
                commit('SET_RENDER_INFO', data);
            } catch (error) {
                console.error('Kan geen renderinfo ophalen:', error.message);
            }
        },
        resetAppContext: ({ commit }) => {
            commit('RESET_APP_CONTENT');
        }
    };

    const mutations = {
        SET_RENDER_INFO: (state, renderInfo) => {
            state.isCampaignPeriod = renderInfo.isCampagneperiode;
            state.language = renderInfo.taal;
            state.showProvisionInfo = renderInfo.showProvisionInfo;
            state.showDmr = renderInfo.dmrTonen;
            state.campaignYear = renderInfo.premiejaar;
            state.daysUntilStartNewCampaignYear = renderInfo.aantalDagenTotStartPremiejaar;
        },
        SET_APP_TYPE: (state, appType) => {
            state.appType = appType;
        },
        SET_BRAND: (state, brand) => {
            state.brand = brand;
        },
        SET_LANGUAGE: (state, value) => {
            state.language = value;
        },
        RESET_APP_CONTENT: state => {
            state.appContext = createVueAppContext();
        }
    };
    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
};

export const renderInfoStoragePaths = ['renderInfo'];

export default app => createStore(app);
