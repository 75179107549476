import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
/**
 *@module usePrefillOfferteApp
 *@description This composable handles the prefill of offerte data in the app.
 * It watches for changes in the Vuex store's 'offerteDataInvalid' and 'canRedirect' values.
 * Depending on these values, it either redirects the user to the home page or the '/pakket-kiezen' page.
 *
 *@example
 * <script setup>
 * import { usePrefillOfferteApp } from '@/composables/use-prefill-offerte-app';
 * *
 * usePrefillOfferteApp();
 * </script>
 */
export const usePrefillOfferteApp = () => {
    const store = useStore();
    const router = useRouter();

    // Computed properties to get values from the store
    const offerteDataInvalid = computed(() => store.getters['prefill/getOfferteDataInvalid']);
    const canRedirect = computed(() => store.getters['prefill/canRedirect']);

    // Function to set the offerteDataInvalid state in the store
    const setOfferteDataInvalid = value => store.dispatch('prefill/setOfferteDataInvalid', value);

    // Watcher for the offerteDataInvalid computed property
    watch(offerteDataInvalid, invalid => {
        if (invalid) {
            router.push('/');
            setOfferteDataInvalid(false);
        }
    });

    // Watcher for the canRedirect computed property
    watch(canRedirect, shouldRedirect => {
        if (shouldRedirect) {
            router.push('/pakket-kiezen');
        }
    });

    // return for testing
    return { offerteDataInvalid, canRedirect, setOfferteDataInvalid };
};
