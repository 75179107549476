import { watch, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { pushOptimizeEvent } from '@/utils/googleOptimize';

export const useOptimizeApp = () => {
    const store = useStore();
    const route = useRoute();
    const { appContext } = inject('jss');

    const isProspectFlow = computed(() => store.getters['renderInfo/isProspectFlow']);
    const propositie = computed(() => appContext().propositie);

    watch(route, ({ params: { sitecoreRoute } }) => {
        ctx.setOptimize(sitecoreRoute[0]);
    });

    const setOptimize = sitecoreRoute => {
        const propositieNaam = ctx.getPropositieNaam(propositie.value);
        // Google Optimize
        const optimizeTags = [
            propositieNaam,
            isProspectFlow.value ? 'pros' : 'wijz',
            sitecoreRoute === '' ? 'start' : sitecoreRoute?.replace(/-/g, '')
        ]
            .filter(n => n)
            .join('.');

        try {
            // Check if `dataLayer` exists, used by Google Optimize
            // if so, push custom event in the following format:
            // 'propositie.flow.pagina'
            // e.g. `aon.wijzigen.pakketkiezen`
            pushOptimizeEvent(optimizeTags);
        } catch (error) {
            console.log('optimize errors', error);
        }
    };

    const getPropositieNaam = propositie => {
        const propositieLijst = {
            BAP: '',
            DPR: 'ziezo',
            AON: 'aon'
        };

        return propositieLijst[propositie];
    };

    // injectable methods ctx for testing
    const ctx = {
        setOptimize,
        getPropositieNaam
    };

    // return for testing
    return { isProspectFlow, propositie, ctx };
};
