import { collectivitySearchStoragePaths } from './modules/collectiviteitszoeker';
import { contractsStoragePaths } from './modules/contracten';
import { loginStoragePaths } from './modules/login';
import { medicalQuestionsStoragePaths } from './modules/medischevragen';
import { productsStorePaths } from './modules/pakketten';
import { prefillStoragePaths } from './modules/prefill';
import { renderInfoStoragePaths } from './modules/renderInfo';
import { stappenplanStoragePaths } from './modules/stappenplan';
import { tvKeuzehulpStoragePaths } from './modules/tvkeuzehulp';
import { usersStoragePaths } from './modules/users';

// To store specific keys for a store module in sessionStorage,
// A separate list is created in corresponding modules.
// This is done to prevent storing 'moment' based states like `error` and `pending` states,
// Which can break the application during a refresh.
// All lists are concatenated here, creating a flat list of all storage paths
const storagePaths = [].concat(
    collectivitySearchStoragePaths,
    contractsStoragePaths,
    loginStoragePaths,
    medicalQuestionsStoragePaths,
    productsStorePaths,
    prefillStoragePaths,
    renderInfoStoragePaths,
    stappenplanStoragePaths,
    tvKeuzehulpStoragePaths,
    usersStoragePaths
);

export default storagePaths;
