// This module contains utility functions for working with Google Optimize.

/**
 * Checks if Google Optimize is available on the current page.
 * @returns {boolean} - True if Google Optimize is available, false otherwise.
 */
export const isGoogleOptimizeAvailable = () => {
    // Checks if the window.dataLayer object is defined, indicating that Google Optimize is available.
    return typeof window.dataLayer !== 'undefined';
};

/**
 * Pushes an event to Google Optimize.
 * @param {string} optimizeTags - The tags to be pushed as an event to Google Optimize.
 * @param {Function} [isAvailable=isGoogleOptimizeAvailable] - A function that checks if Google Optimize is available. Used for testing.
 */
export const pushOptimizeEvent = (optimizeTags, isAvailable = isGoogleOptimizeAvailable) => {
    // If Google Optimize is not available, exit the function early.
    if (!isAvailable()) return;

    // Push the optimizeTags to the dataLayer, which will trigger the corresponding experiment in Google Optimize.
    window.dataLayer.push({ event: optimizeTags });
};
