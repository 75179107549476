import { ApiClient } from '@/utils/apiClient';

const createStore = app => {
    const state = {
        allianties: {},
        zoekResultaten: [],
        pendingSearch: false,
        isApiUnavailable: true
    };

    const getters = {
        getAllianties: (state, _getters, _rootState, rootGetters) => {
            const taal = rootGetters['renderInfo/getTaal'] === 'nl' ? 'naam' : 'naamEn';
            if (!state.allianties) return;

            return Object.values(state.allianties).map(alliantie => alliantie[taal]);
        },
        getZoekResultaten: state => state.zoekResultaten,
        getPendingSearch: state => state.pendingSearch,
        getPendingAlliantie: state => state.pendingAlliantie,
        getApiStatus: state => state.isApiUnavailable
    };

    const actions = {
        clearZoekResultaten({ commit }) {
            commit('CLEAR_ZOEK_RESULTATEN');
        },
        clearAllianties({ commit }) {
            commit('CLEAR_ALLIANTIES');
        },
        async checkApiStatus({ commit }) {
            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const propositie = appContext?.propositie;

                await ApiClient(appContext).post(
                    `/clientcontroller/collectiviteitzoekerclient/Zoeken`,
                    {
                        Versie: 'SF',
                        Propositie: propositie,
                        Zoektekst: 'achmea'
                    }
                );
                commit('SET_API_UNAVAILABLE', false);
            } catch (error) {
                commit('SET_API_UNAVAILABLE', true);
            }
        },
        async ophalenZoekResultaten({ commit, dispatch }, zoekterm) {
            commit('CLEAR_ZOEK_RESULTATEN');

            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const propositie = appContext?.propositie;

                commit('SET_PENDING_SEARCH', true);

                // Versie opties zijn: SF | KD | <leeg>
                // Propositie: BAP | DFR
                // Zoektekst: wees creatief

                const { data } = await ApiClient(appContext).post(
                    `/clientcontroller/collectiviteitzoekerclient/Zoeken`,
                    {
                        Versie: 'SF',
                        Propositie: propositie,
                        Zoektekst: zoekterm
                    }
                );

                if (data) {
                    commit('SET_ZOEK_RESULTATEN', data.resultaten);
                } else {
                    dispatch('setComponentErrorStatus', { zoeker: true }, { root: true });
                }
            } catch (error) {
                dispatch('setComponentErrorStatus', { zoeker: true }, { root: true });
                commit('SET_API_UNAVAILABLE', true);
            } finally {
                commit('SET_PENDING_SEARCH', false);
            }
        },
        async ophalenAllianties({ dispatch, commit }) {
            try {
                const appContext = app.config.globalProperties.$jss.appContext();
                const propositie = appContext?.propositie;

                commit('SET_PENDING_ALLIANTIE', true);

                const { data } = await ApiClient(appContext).get(
                    `/clientcontroller/collectiviteitzoekerclient/allianties?propositie=${propositie}`
                );

                if (data) {
                    commit('SET_ALLIANTIES', data.allianties);
                } else {
                    dispatch('setComponentErrorStatus', { allianties: true }, { root: true });
                }
            } catch (error) {
                dispatch('setComponentErrorStatus', { allianties: true }, { root: true });
            } finally {
                commit('SET_PENDING_ALLIANTIE', false);
            }
        },
        setAlliantieResultaten({ state, commit, rootGetters }, alliantieNaam) {
            const taal = rootGetters['renderInfo/getTaal'] === 'nl' ? 'naam' : 'naamEn';

            commit(
                'SET_ZOEK_RESULTATEN',
                Object.values(state.allianties).find(
                    allianties => allianties[taal] === alliantieNaam
                ).collectiviteiten
            );
        },
        setPendingSearchState: ({ commit }, payload) => {
            commit('SET_PENDING_SEARCH', payload);
        },
        setPendingAlliantieState: ({ commit }, payload) => {
            commit('SET_PENDING_ALLIANTIE', payload);
        }
    };

    const mutations = {
        SET_ALLIANTIES(state, allianties) {
            state.allianties = { ...state.allianties, ...allianties };
        },
        SET_ZOEK_RESULTATEN(state, results) {
            state.zoekResultaten = results;
        },
        CLEAR_ZOEK_RESULTATEN(state) {
            state.zoekResultaten = [];
        },
        CLEAR_ALLIANTIES(state) {
            state.allianties = {};
        },
        SET_API_UNAVAILABLE(state, payload) {
            state.isApiUnavailable = payload;
        },
        SET_PENDING_SEARCH(state, payload) {
            state.pendingSearch = payload;
        },
        SET_PENDING_ALLIANTIE(state, payload) {
            state.pendingAlliantie = payload;
        }
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
};

export const collectivitySearchStoragePaths = ['collectiviteitszoeker'];

export default app => createStore(app);
