import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import storagePaths from './storage-paths';
import storeConfig from './storeConfig';

const createStoreConfig = app => {
    const config = storeConfig(app);

    // "istanbul ignore next" prevents this code to be included in test coverage
    // since this is a browser specific code execution and can not be tested with jest
    /* istanbul ignore next */
    if (typeof window !== 'undefined') {
        // set persisted state for store module data
        config.plugins.push(
            createPersistedState({
                key: 'salesfunnel',
                storage: window.sessionStorage,
                paths: storagePaths
            })
        );
    }

    return config;
};

export default app => createStore(createStoreConfig(app));
