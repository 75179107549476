/**
 * Geeft een nieuwe datum object terug met 0 uren, zodat datums kunnen worden vergeleken, onafhankelijk van tijd.
 * @note Dit is een helper functie en wordt enkel binnen deze util gebruikt. Geëxporteerd voor Jest.
 * @param {string|Date} datum Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat.
 * @returns {Date}
 */
export const createDatum = datum => {
    const d = new Date(datum);
    if (d.toString() === 'Invalid Date') {
        throw new Error(`Er is een invalide datum parameter opgegeven. Opgegeven datum: ${datum}`);
    }

    d.setHours(0, 0, 0, 0);

    return d;
};

/**
 * Geeft een nieuwe datum terug op basis van jaar, maand en dag. Uren worden op nul gezet, voor onafhankelijk vergelijken
 * @note Dit is een helper functie en wordt enkel binnen deze util gebruikt.
 * @param {number} jaar
 * @param {number} maand
 * @param {number} dag
 * @returns {Date}
 */
const createCustomDatum = (jaar, maand, dag) => new Date(jaar, maand, dag, 0, 0, 0, 0);

/**
 * Geeft een datum object terug voor gebruik als systeemdatum waarmee datums kunnen worden vergeleken.
 * @note Dit is een helper functie en wordt enkel binnen deze util gebruikt. Geëxporteerd voor Jest.
 * @param {string|Date} [datum] Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat. Valt terug op huidige datum.
 * @returns {Date}
 */
export const createSysteemDatum = datum => createDatum(datum || new Date());

/**
 * Geeft een datum object terug voor gebruik eerste dag van de volgende kalendermaand.
 * @note Dit is een helper functie en wordt enkel binnen deze util gebruikt. Geëxporteerd voor Jest.
 * @param {string|Date} [datum] Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat. Valt terug op huidige datum.
 * @returns {Date}
 */
const createDatumEersteDagVolgendeMaandNa18Jaar = datum => {
    const d = createDatum(datum);
    return createCustomDatum(d.getFullYear() + 18, d.getMonth() + 1, 1);
};

/**
 * Controleert of leeftijd minimaal 17 jaar en 9 maanden is op basis van geboortedatum.
 * @param {string|Date} datum Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat.
 * @param {string|Date} [customSysteemDatum] Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat. Valt terug op huidige datum.
 * @returns {boolean}
 */
export const isLeeftijdVanaf17Jaar9Maanden = (datum, customSysteemDatum) => {
    const d = createDatum(datum);
    const systeemdatum = createSysteemDatum(customSysteemDatum);
    const datum17Jaar9Maanden = createCustomDatum(
        systeemdatum.getFullYear() - 18,
        systeemdatum.getMonth() + 3,
        systeemdatum.getDate()
    );

    return d <= datum17Jaar9Maanden;
};

/**
 * Controleert of leeftijd minimaal 17 jaar, 10 maanden en 1 dag is op basis van geboortedatum.
 * @param {string|Date} datum Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat.
 * @param {string|Date} [customSysteemDatum] Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat. Valt terug op huidige datum.
 * @returns {boolean}
 */
export const isLeeftijdVanaf17Jaar10Maanden1Dag = (datum, customSysteemDatum) => {
    const d = createDatum(datum);
    const systeemdatum = createSysteemDatum(customSysteemDatum);
    const datum17Jaar10Maanden = createCustomDatum(
        systeemdatum.getFullYear() - 18,
        systeemdatum.getMonth() + 2,
        systeemdatum.getDate() - 1
    );

    return d <= datum17Jaar10Maanden;
};

/**
 * Controleert of leeftijd 18 jaar is op basis van geboortedatum.
 * @param {string|Date} datum Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat.
 * @param {string|Date} [customSysteemDatum] Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat. Valt terug op huidige datum.
 * @returns {boolean}
 */
export const isLeeftijdVanaf18Jaar = (datum, customSysteemDatum) => {
    const d = createDatum(datum);
    const systeemdatum = createSysteemDatum(customSysteemDatum);
    const datum18Jaar = createCustomDatum(
        systeemdatum.getFullYear() - 18,
        systeemdatum.getMonth(),
        systeemdatum.getDate()
    );

    return d <= datum18Jaar;
};

/**
 * Controleert of leeftijd onder 120 jaar is op basis van geboortedatum.
 * @param {string|Date} datum Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat.
 * @param {string|Date} [customSysteemDatum] Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat. Valt terug op huidige datum.
 * @returns {boolean}
 */
export const isLeeftijdOnder120Jaar = (datum, customSysteemDatum) => {
    const d = createDatum(datum);
    const systeemdatum = createSysteemDatum(customSysteemDatum);
    const datum120Jaar = createCustomDatum(
        systeemdatum.getFullYear() - 120,
        systeemdatum.getMonth(),
        systeemdatum.getDate()
    );

    return d > datum120Jaar;
};

/**
 * Controleert of leeftijd minimaal 17 jaar, 10 maanden en 1 dag is op basis van geboortedatum.
 * Indien 18 jaar: kijken of huidige datum loopt tot een maand na de achttiende verjaardag.
 * @param {string|Date} datum Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat.
 * @param {string|Date} [customSysteemDatum] Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat. Valt terug op huidige datum.
 * @returns {boolean}
 */
export const isLeeftijdTussen17Jaar10Maanden1DagEn18Jaar1Maand = (datum, customSysteemDatum) => {
    const d = createDatum(datum);
    const systeemdatum = createSysteemDatum(customSysteemDatum);

    if (isLeeftijdVanaf17Jaar10Maanden1Dag(datum, systeemdatum)) {
        if (isLeeftijdVanaf18Jaar(datum, systeemdatum)) {
            const datum18Jaar1Maand = createCustomDatum(
                d.getFullYear() + 18,
                d.getMonth() + 1,
                d.getDate()
            );

            return systeemdatum < datum18Jaar1Maand;
        }

        return true;
    }

    return false;
};

/**
 * Controleert of leeftijd minimaal 17 jaar en 9 maanden is op basis van geboortedatum.
 * Indien 18 jaar: kijken of huidige datum loopt tot de eerste dag van de volgende maand na de achttiende verjaardag.
 * @param {string|Date} datum Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat.
 * @param {string|Date} [customSysteemDatum] Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat. Valt terug op huidige datum.
 * @returns {boolean}
 */
export const isLeeftijdTussen17Jaar9MaandenEnEersteNa18Jaar = (datum, customSysteemDatum) => {
    const systeemdatum = createSysteemDatum(customSysteemDatum);
    const datumEersteDagVolgendeMaandNa18Jaar = createDatumEersteDagVolgendeMaandNa18Jaar(datum);

    if (isLeeftijdVanaf17Jaar9Maanden(datum, systeemdatum)) {
        if (isLeeftijdVanaf18Jaar(datum, systeemdatum)) {
            return systeemdatum < datumEersteDagVolgendeMaandNa18Jaar;
        }
        return true;
    }
    return false;
};

/**
 * Controleert of leeftijd minimaal 17 jaar, 10 maanden en 1 dag is op basis van geboortedatum.
 * Indien 18 jaar: kijken of huidige datum loopt tot de eerste dag van de volgende maand na de achttiende verjaardag.
 * @param {string|Date} datum Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat.
 * @param {string|Date} [customSysteemDatum] Een JavaScript `Date` object of datum `string` in `YYYY-MM-DD` formaat. Valt terug op huidige datum.
 * @returns {boolean}
 */
export const isLeeftijdTussen17Jaar10Maanden1DagEnEersteNa18Jaar = (datum, customSysteemDatum) => {
    const systeemdatum = createSysteemDatum(customSysteemDatum);
    const datumEersteDagVolgendeMaandNa18Jaar = createDatumEersteDagVolgendeMaandNa18Jaar(datum);

    if (isLeeftijdVanaf17Jaar10Maanden1Dag(datum, systeemdatum)) {
        if (isLeeftijdVanaf18Jaar(datum, systeemdatum)) {
            return systeemdatum < datumEersteDagVolgendeMaandNa18Jaar;
        }
        return true;
    }
    return false;
};

/**
 * Controleert of een leeftijd is veranderd van minderjarig
 * (< 17 jaar, 10 maanden en 1 dag) naar meerderjarig (>= 17 jaar, 10 maanden en 1 dag en andersom
 * @param {string} datumOud Voorbeeld: `1990-12-12`
 * @param {string} datumNieuw Voorbeeld: `2000-12-12`
 * @returns {boolean}
 */
export const isLeeftijdAangepast = (datumOud, datumNieuw) => {
    return !datumOud || !datumNieuw
        ? false
        : isLeeftijdVanaf17Jaar10Maanden1Dag(datumOud)
          ? !isLeeftijdVanaf17Jaar10Maanden1Dag(datumNieuw)
          : isLeeftijdVanaf17Jaar10Maanden1Dag(datumNieuw);
};
