<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { defineAsyncComponent, computed } from 'vue';
import { Placeholder, isEditorActive } from '@sitecore-jss/sitecore-jss-vue';

const props = defineProps({
    route: {
        type: Object,
        default: () => ({})
    }
});

const SvgSprites = defineAsyncComponent(
    () =>
        import(
            /* webpackChunkName: "svgsprites.chunk" */
            '@/SvgSprites.vue'
        )
);

const isDevelopment = computed(() => {
    return process.env.NODE_ENV === 'development';
});
</script>

<template>
    <div class="content">
        <Placeholder
            name="jss-main"
            :rendering="props.route"
        />
        <SvgSprites v-if="isDevelopment || isEditorActive()" />
    </div>
</template>
