import { APP_TYPE_PROSPECT, APP_TYPE_WIJZIGEN } from '@/constants';
import { ApiClient } from '@/utils/apiClient';

const createStore = app => {
    const state = {
        relatienummer: null,
        klantSessieNietActief: false,
        isVerzekeringnemerIngelogd: false,
        error: false
    };

    const getters = {
        getRelatienummer: state => state.relatienummer,
        getKlantSessieNietActief: state => state.klantSessieNietActief,
        getIsVerzekeringnemerIngelogd: state => state.isVerzekeringnemerIngelogd,
        getErrorStatus: state => state.error
    };

    const actions = {
        setRelatienummer: ({ getters, commit, dispatch, rootGetters }, relatienummer) => {
            // check afwijkende relatie
            if (relatienummer !== getters.getRelatienummer) {
                // clear pakketten en users indien ander relatienummer
                dispatch('pakketten/clear', null, { root: true });
                dispatch('users/clear', null, { root: true });

                // opslaan nieuw relatienummer
                commit('SET_RELATIENUMMER', relatienummer);

                // ophalen nieuwe pakketten indien nodig
                switch (rootGetters['renderInfo/getAppType']) {
                    case APP_TYPE_PROSPECT:
                    case APP_TYPE_WIJZIGEN:
                        dispatch('pakketten/ophalen', null, { root: true });
                        break;
                }
            }
        },
        setKlantSessieNietActief: ({ commit }, klantSessieNietActief) => {
            commit('SET_KLANT_SESSIE_NIET_ACTIEF', klantSessieNietActief);
        },
        setIsVerzekeringnemerIngelogd: ({ commit }, isVznIngelogd) => {
            commit('SET_IS_VZN_INGELOGD', isVznIngelogd);
        },
        setErrorStatus: ({ commit }, error) => {
            commit('SET_ERROR_STATUS', error);
        },
        async ophalenLoginInfo({ dispatch }) {
            const appContext = app.config.globalProperties.$jss.appContext();

            // Login informatie hoeft niet te worden opgehaald in de prospect flow
            if (appContext?.appType === APP_TYPE_PROSPECT) return;

            dispatch('setPendingStatus', true, { root: true });
            dispatch('setErrorStatus', false);

            try {
                // ophalen login info
                const { data } = await ApiClient(appContext).get(
                    `/clientcontroller/salesfunnelclient/ophalenlogininfo`
                );
                dispatch('setKlantSessieNietActief', data.loginAlsKlantSessieNietActief);
                dispatch('setIsVerzekeringnemerIngelogd', data.isVerzekeringnemerIngelogd);
                dispatch('setRelatienummer', data.relatienummer);
            } catch (error) {
                console.log('Kan geen logininfo ophalen:', error.message);
                dispatch('setErrorStatus', true);
            } finally {
                dispatch('setPendingStatus', false, { root: true });
            }
        }
    };

    const mutations = {
        SET_RELATIENUMMER: (state, relatienummer) => {
            state.relatienummer = relatienummer;
        },
        SET_KLANT_SESSIE_NIET_ACTIEF: (state, klantSessieNietActief) => {
            state.klantSessieNietActief = klantSessieNietActief;
        },
        SET_IS_VZN_INGELOGD: (state, isVznIngelogd) => {
            state.isVerzekeringnemerIngelogd = isVznIngelogd;
        },
        SET_ERROR_STATUS: (state, error) => {
            state.error = error;
        }
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
};

export const loginStoragePaths = ['login.relatienummer', 'login.isVerzekeringnemerIngelogd'];

export default app => createStore(app);
