import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "content" };
const _hoisted_2 = { class: "container" };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col" };
import { ref } from 'vue';
import { MlSkeletonTable } from 'design.zorgdomein';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RouteLoading',
    props: {
        delay: { default: 300 }
    },
    setup(__props, { expose: __expose }) {
        const props = __props;
        const visible = ref(false);
        // Wait {delay}ms before making the loading graphic visible.
        // If whatever other async operation completes within that timeframe,
        // then whichever parent component invoked the loading component will
        // presumably re-render without the loading component.
        // This helps prevent the loading graphic from too briefly "flashing" on the screen
        // causing an undesirable flicker for users.
        setTimeout(() => {
            visible.value = true;
        }, props.delay);
        __expose({
            visible
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            (visible.value)
                                ? (_openBlock(), _createBlock(_unref(MlSkeletonTable), { key: 0 }))
                                : _createCommentVNode("", true)
                        ])
                    ])
                ])
            ]));
        };
    }
});
