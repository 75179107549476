import { RestLayoutService } from '@sitecore-jss/sitecore-jss-vue';
import { isServer } from '@/utils/is-server';
import config from '../temp/config';

export class LayoutServiceFactory {
    create(context) {
        return new RestLayoutService({
            apiHost: this.isLocalhost() ? context?.serviceHost : '',
            apiKey: config.sitecoreApiKey,
            siteName: context?.appSiteName, // Use siteName from context
            configurationName: 'jss' // The integrated GraphQL (for SfStepper) does not work on the default layout endpoint out of the box, so use JSS config.
        });
    }

    isLocalhost() {
        return !isServer() && location.hostname === 'localhost';
    }
}

export const layoutServiceFactory = new LayoutServiceFactory();
